import React, { } from "react";

const VideoWallButton = (props) => {


    const handleClick = () => {
        if (props.callback) {
            props.callback(props.zone, props.url, props.poster);
        }
    }

    return (
        <button onClick={handleClick} alt="Next" className="video-wall-button" style={{left: props.xPos, top: props.yPos}}>
            <div className="play-btn"></div>
        </button>
    );
}

export default VideoWallButton;