/** Shelf Life: Video Wall **/

import React from "react";
import FullscreenVideo from "../../components/media/fullscreen-video"
import Video from "../../components/media/video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { scaleFullscreen } from "../../helpers"
import VideoWallButton from "../../components/shelf-life/video-wall-button"


let globalState, data;
const VID_THUMB_WIDTH = 320;
const VID_THUMB_HEIGHT = 240;

class ShelfLifePage5 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[5];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    videoX: 0,
    videoY: 0,
    videoWallUrl: '',
    videoWallPoster: ''
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //add base track
    //globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);

    
    //If coming to this chapter by clicking prev
    if(globalState.transitionDirection === "prev") {
      globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2, 60); //base track
    } else {
      //set volume for base track
      globalState.baseAudioRef.updateVolume(2, 60);
    }

    //Update dimensions
    this.updateDimensions();

    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);

    //Preload assets from next page
    //const preloadData = globalState.assets[2];
    preloadImages([
      globalState.cdnUrl + data.nextTitle
    ], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    //gsap.killTweensOf(this.refs.text1Ref);

    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);

    //remove event listeners
    window.removeEventListener("resize", this.updateDimensions);

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
    this.bgVideoRef.seekVideo(30);
  }

  

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  updateDimensions = () => {
    console.log('updateDimensions');
    scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, VID_THUMB_WIDTH * 4, VID_THUMB_HEIGHT * 3);

  }

  handleVideoBtnClick = (zone, url, poster) => {
    console.log(zone + ", " + url);

    this.setState({ videoWallUrl: "" });

    let videoX, videoY;

    switch (zone) {
      case "1":
        videoX = 0;
        videoY = 0;
        break;

      case "2":
        videoX = 640;
        videoY = 0;
        break;

      case "3":
        videoX = 0;
        videoY = 240;
        break;

      case "4":
        videoX = 640;
        videoY = 240;
        break;

      default:
        videoX = 0;
        videoY = 0;
        break;

    }

    setTimeout(()=>{
      this.setState({ videoX: videoX });
      this.setState({ videoY: videoY });
      this.setState({ videoWallUrl: url });
      this.setState({ videoWallPoster: poster });
      this.refs.videoRef.playVideo();
    }, 100);
    //this.refs.videoRef.playVideo();
  }

  closeVideoBtnClick = () => {
    this.setState({ videoWallUrl: "" });
    //this.refs.videoRef.playVideo();
  }


  render() {

    return (

      <>

        <link rel="prefetch" href="/whats-weird" />
        {data && (
        <>
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo1} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo2} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo3} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo4} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo5} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo6} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo7} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo8} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo9} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo10} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo11} />
        <link rel="preload" as="video" href={globalState.videoUrl + data.demo12} />
        </>
        )}
        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/whats-weird" prevLocation="/shelf-life/4"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Shelf Life" />

          <img className="caption" src={globalState.cdnUrl + data.caption} alt="VIDEOS TAKEN IN FINAL WEEK BY TED SHUTSA" style={{top: '70px', right: '30px', position: 'absolute', zIndex: 1}} />

          <div className="fullscreen-bg video-wall" ref="fullscreenBgRef" >
            {data.bgVideo ?
              <div className="video-wall-wrapper">
                <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
                  poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} muted={true} ref={div => this.bgVideoRef = div} fullscreenClass={''} globalState={globalState} />
              </div> : ""
            }
            <VideoWallButton xPos="0" yPos="0" zone="1" url={globalState.videoUrl + data.demo1} poster={globalState.cdnUrl + data.demo1Poster} callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="320px" yPos="0" zone="1" url={globalState.videoUrl + data.demo2} poster={globalState.cdnUrl + data.demo2Poster}callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="640px" yPos="0" zone="2" url={globalState.videoUrl + data.demo3} poster={globalState.cdnUrl + data.demo3Poster} callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="960px" yPos="0" zone="2" url={globalState.videoUrl + data.demo4} poster={globalState.cdnUrl + data.demo4Poster} callback={this.handleVideoBtnClick} />

            <VideoWallButton xPos="0" yPos="240px" zone="1" url={globalState.videoUrl + data.demo5} poster={globalState.cdnUrl + data.demo5Poster} callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="320px" yPos="240px" zone="1" url={globalState.videoUrl + data.demo6} poster={globalState.cdnUrl + data.demo6Poster} callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="640px" yPos="240px" zone="2" url={globalState.videoUrl + data.demo7} poster={globalState.cdnUrl + data.demo7Poster} callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="960px" yPos="240px" zone="2" url={globalState.videoUrl + data.demo8} poster={globalState.cdnUrl + data.demo8Poster} callback={this.handleVideoBtnClick} />

            <VideoWallButton xPos="0" yPos="480px" zone="3" url={globalState.videoUrl + data.demo9} poster={globalState.cdnUrl + data.demo9Poster} callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="320px" yPos="480px" zone="3" url={globalState.videoUrl + data.demo10} poster={globalState.cdnUrl + data.demo10Poster} callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="640px" yPos="480px" zone="4" url={globalState.videoUrl + data.demo11} poster={globalState.cdnUrl + data.demo11Poster} callback={this.handleVideoBtnClick} />
            <VideoWallButton xPos="960px" yPos="480px" zone="4" url={globalState.videoUrl + data.demo12} poster={globalState.cdnUrl + data.demo12Poster} callback={this.handleVideoBtnClick} />

            {this.state.videoWallUrl.length > 0 ?
              <button className="video-wrapper" onClick={this.closeVideoBtnClick} style={{ left: this.state.videoX + "px", top: this.state.videoY + "px" }}>
                <Video ref="videoRef" autoPlay={true} loop={true} poster={this.state.videoWallPoster} file={this.state.videoWallUrl} muted={false} width="640px" height="480px" globalState={globalState} />
              </button>
              : ""}
          </div>

        </div>
        )}
      </>
    );
  }


};

export default ShelfLifePage5
