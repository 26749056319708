import React from "react"
import { gsap } from "gsap"

let timeInterval;

class Video extends React.Component {

    state = {
        isPlaying: false,
        duration: 0,
        time: 0
    }

    seekVideo = (seconds, playAfterSeek = true) => {
        this.refs.videoRef.currentTime = seconds;
        if(playAfterSeek){
            this.refs.videoRef.play();
        }
    }

    hideVideo = (time = 0.2) => {
        console.log('hideVideo');
        gsap.to(this.refs.videoRef, time, { autoAlpha: 0 });
    }

    showVideo = (time = 0.2) => {
        console.log('showVideo');
        gsap.to(this.refs.videoRef, time, { autoAlpha: 1 });
    }

    playVideo = () => {
        this.refs.videoRef.play();
        this.setState({isPlaying: true});
    }

    pauseVideo = () => {
        this.refs.videoRef.pause();
        this.setState({isPlaying: false});
    }
    videoEnded = () => {
        console.log(
            'VIDEO ENDED'
        )
        if(this.props.ended){
            this.props.ended();
        }
    }
    muteVideo = () => {
        this.refs.videoRef.muted = true;
    }
    componentDidMount() {
        this.refs.videoRef.addEventListener("ended", this.videoEnded);
        this.refs.videoRef.disablePictureInPicture = true;
        
        try{
            if(this.props.globalState.baseAudioRef.isMuted()) {
                this.muteVideo();
            }
        } catch(e){
            console.warn(e);
        }

        timeInterval = setInterval(() => {
            if(this.refs.videoRef.readyState > 0) {
                this.setState({time: this.refs.videoRef.currentTime});
                this.setState({duration: this.refs.videoRef.duration});
            }
        }, 100);

        // subscribe state change
        //this.refs.videoRef.subscribeToStateChange(this.handleStateChange.bind(this));

        //Hls is added in gatsby-ssr.js
        /* eslint-disable */
        let videoStream = this.props.file;

        if (Hls.isSupported()) {
            var video = this.refs.videoRef;
            var hls = new Hls();
            // bind them together
            hls.attachMedia(video);
            // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                console.log("video and hls.js are now bound together !");
                hls.loadSource(videoStream);
                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    console.log("manifest loaded, found " + data.levels.length + " quality level");
                });
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = videoStream; //'https://video-dev.github.io/streams/x36xhzz/x36xhzz.m3u8';
            console.log("Set html5 video src to " + videoStream);
            video.addEventListener('canplay',function() {
                console.log('video can play');
              video.play();
            });
        }

        /*eslint-enable */
    }

    componentWillUnmount() {
        clearInterval(timeInterval);
    }

    handleStateChange(state, prevState) {
        // copy player state to this component's state
        this.setState({
            player: state,
            currentTime: state.currentTime
        });
    }

    updateState(pieceOfState){
        console.log('updateState: ' + pieceOfState);
        this.setState(pieceOfState);
    }

    render() {
        return (
            <>
            
            <video ref="videoRef" poster={this.props.poster} autoPlay={this.props.autoPlay} loop={this.props.loop} muted={this.props.muted} className="video-player" style={{width: this.props.width + 'px', height: this.props.height + 'px'}}>
                <track kind="captions" srcLang="en" />
            </video>
            </>
        )
    }
}

export default Video
